<style lang="scss" scoped>
@import "assets/scss/default.scss";
</style>

<template>
	<div>
		<Header></Header>
		<div id="wrapper">
			<Navbar :navLinks="navLinks"></Navbar>
			<main>
				<slot/>
			</main>
		</div>
	</div>
</template>

<script setup>
const { $account } = useNuxtApp();
const navLinks = $account && $account.subscription_plan ? [
	{ url: "/dashboard", name: "Accueil", icon: "isax isax-element-4" },
	{ url: "/orders", name: "Commandes", icon: "isax isax-document-copy" },
	{ url: "/customers", name: "Clients", icon: "isax isax-user-search" },
	{ url: "/payments", name: "Transactions", icon: "isax isax-repeat" }
] : [
	{ url: "/subscriptions", name: "Abonnements", icon: "isax isax-award" }
];
</script>

<script>
export default {
	name: "DefaultLayout",
	mounted() {
		const { $events } = useNuxtApp();
		// Loader
		$events.on("loading", ([state, text, fullHeight]) => {
			this.loadingText = text || "loader.loading";
			this.loading = state;
			this.fullHeight = fullHeight;

			// If loading add overflow hidden
			if (state) document.body.classList.add("overflow-hidden");
			else document.body.classList.remove("overflow-hidden");
			// Move the scroll to the top
			window.scrollTo(0, 0);
		});
	}
};
</script>
